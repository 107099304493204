<template>
  <div class="tLine">
    将为"{{ dataValue.crmTypeName }}"版块上传一个BOM记录：
  </div>
  <div class="dcbDiv">

    <div v-for="(aDCB,i) in dataValue.dcb" class="aDCB">

      <div class="title">{{ i + 1 }}、{{ aDCB["title"] }}</div>

      <a-radio-group v-if="aDCB['typeId'] === 0" class="xx" v-model:value="aDCB['checkValue']">
        <template v-for="aXZ in aDCB['value']">
          <a-radio :value="aXZ">{{ aXZ }}</a-radio>
        </template>
      </a-radio-group>

      <a-checkbox-group v-if="aDCB['typeId'] === 1" class="xx" v-model:value="aDCB['checkValues']">
        <template v-for="aXZ in aDCB['value']">
          <a-checkbox :value="aXZ">{{ aXZ }}</a-checkbox>
        </template>
      </a-checkbox-group>

    </div>


  </div>
  <div style="float: right; width: 40%;">
    <div class="searchCompany">
      <div class="titleNode">请选择一个公司:</div>
      <a-select
          v-model:value="dataValue.company.value"
          show-search
          placeholder="录入公司名字"
          style="width:100%"
          :options="dataValue.company.options"
          :filter-option="allEvent.company.filterOption"
          @change="allEvent.company.handleChange"
      ></a-select>
    </div>
    <div class="aSelect" v-if="crm_type_id === 2">
      <div class="titleNode">贸易商子类型：</div>
      <a-select v-model:value="dataValue.userInput.crmTypeId2" class="select">
        <a-select-option :value="1">外资</a-select-option>
        <a-select-option :value="2">现货</a-select-option>
        <a-select-option :value="3">渠道</a-select-option>
        <a-select-option :value="4">统货</a-select-option>
      </a-select>
    </div>
    <div class="aSelect" v-if="crm_type_id === 1">
      <div class="titleNode">选择货币类型：</div>
      <a-select v-model:value="dataValue.factoryInput.moneyTypeName" class="select">
        <a-select-option value="RMB未税">RMB未税</a-select-option>
        <a-select-option value="USD">USD</a-select-option>
        <a-select-option value="RMB含税">RMB含税</a-select-option>
        <a-select-option value="其他">其他</a-select-option>
      </a-select>
    </div>
    <div style="float: left; clear: both;">
      <div class="titleNode">选择一个上传的文件：</div>
      <a-upload :file-list="dataValue.fileList" list-type="picture" :max-count="1" :before-upload="beforeUpload"
                @remove="handleRemove" class="aUpload">
        <a-button>
          <upload-outlined></upload-outlined>
          选择并上传一个BOM
        </a-button>
      </a-upload>
      <label class="lInfo">* 只可以上传识别标准的excel文件。</label>
    </div>
    <div class="buttons">
      <a-button
          type="primary"
          :disabled="dataValue.fileList.length === 0"
          :loading="dataValue.uploading"
          style="margin-top: 16px"
          @click="allEvent.upEvent"
      >
        {{ dataValue.uploading ? '上传并处理文件中 ...' : '提交上传BOM' }}
      </a-button>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "add_bom",
  props : [ 'crm_type_id' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      company : {
        searchKey : "",
        value : "",
        options : [ {
          value : '33',
          label : '深圳市芯链芯数据技术有限公司',
        } ],
      },

      dcb : [],

      crmTypeId : props.crm_type_id,
      crmTypeName : '',
      fileList : [],
      uploading : false,

      userInput : {
        companyId : 0,
        crmTypeId : 0,
        crmTypeId2 : 1,
        dcb : [],
        list : []
      },

      factoryInput : {
        fileIndex : "",
        crmCompanyId : 0,
        moneyTypeName : "RMB未税",
        dcb : {}
      }
    })


    switch (dataValue.crmTypeId) {
      case 1: {
        dataValue.crmTypeName = '工厂库存';
        break;
      }
      case 2: {
        dataValue.crmTypeName = '贸易商';
        break;
      }
      case 3: {
        dataValue.crmTypeName = '工厂销售';
        break;
      }
    }


    const handleRemove = file => {
      const index = dataValue.fileList.indexOf(file);
      const newFileList = dataValue.fileList.slice();
      newFileList.splice(index, 1);
      dataValue.fileList = newFileList;
    };

    const beforeUpload = file => {
      dataValue.fileList = [];
      dataValue.fileList = [ ...dataValue.fileList, file ];
      return false;
    };


    let allEvent = {

      init : () => {

        let addDCB = (idName, tInfo, typeValue, ...vInfo) => {
          dataValue.dcb.push({
            "idName" : idName,
            "title" : tInfo,
            "typeId" : typeValue,
            "value" : vInfo,
            "checkValue" : {},
            "checkValues" : [],
          });
        }

        if (dataValue.crmTypeId === 1) {
          addDCB("dcb1", "闲置处理方式", 1, "挑料单卖", "整表打包一口价", "以上二者都要", "价格不合适可以寄售");
          addDCB("dcb2", "处理的速度", 0, "越快越好", "不着急，慢慢卖");
          addDCB("dcb3", "每年闲置处理情况", 0, "1个月都有", "3个月一个季度一次", "6个月一次", "12个月一次", "随时处理");
          addDCB("dcb4", "闲置物料需要处理的原因", 1, "项目物料不整齐", "项目停产EOL", "物料更新换代", "备库数量和在库时间超出安全值", "公司破产清算", "闲置的固定比例清货", "现货买多了，通过销售其他物料，补现货的KPI的损失。", "上市公司提供降库存，提高利润率。", "其他");
          addDCB("dcb5", "闲置处理价格预期", 0, "折扣", "保值", "增值");
          // addDCB("dcb6", "计划交易币种", 0, "RMB未税", "USD", "RMB含税", "其他");
          addDCB("dcb7", "库存所在地", 1, "国内", "香港", "台湾", "保税区", "其他");
          addDCB("dcb8", "采购渠道组成", 1, "原厂", "代理", "贸易");
          addDCB("dcb9", "上传DC信息", 0, "1年内", "2年内", "3年内", "3年以上", "5年以上");
          addDCB("dcb10", "付款后，交货交期确认", 0, "1周内", "2周内", "3周内", "4周内", "4周以上");
          addDCB("dcb11", "是否有物料可以长期销售", 0, "是", "否");
        } else {
          addDCB("1", "请问您之前是否在其他平台询过该批物料吗？", 0, "是", "否");
          addDCB("2", "在本平台，您希望得到？", 0, "查询价格", "真实货源");
          addDCB("3", "目前您能接受？", 0, "现货", "排单");
          addDCB("4", "您认为什么原因对自己缺货影响较大？", 0, "供应端原厂交期不足", "客户潜在需求增加", "自己战略备货", "以上情况都有", "其他原因");
          addDCB("5", "您认为缺货带来的最差后果是？", 0, "砍单", "停线", "以上情况都有", "无影响");
        }

      },

      company : {

        getOption : (inputKey) => {

          if (inputKey === dataValue.company.searchKey) return;

          dataValue.company.searchKey = inputKey;

          proxy.post('/admin/crm/search', { "companyNameKey" : inputKey, "crmTypeId" : dataValue.crmTypeId })
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  dataValue.company.options = res.data['list'];
                }
              })

        },


        filterOption : (inputKey, option) => {
          allEvent.company.getOption(inputKey);
          return true;

        }

      },


      upEvent : () => {

        if (dataValue.company.value === "" || isNaN(parseInt(dataValue.company.value)) || parseInt(dataValue.company.value) === 0) {
          message.error("请选择一个公司");
          return;
        }

        dataValue.uploading = true;
        dataValue.userInput.companyId = parseInt(dataValue.company.value);
        dataValue.userInput.crmTypeId = dataValue.crmTypeId;
        dataValue.factoryInput.companyId = parseInt(dataValue.company.value);
        dataValue.factoryInput.crmTypeId = dataValue.crmTypeId;

        if (dataValue.crmTypeId === 1) allEvent.factory.batchUp1();
        if (dataValue.crmTypeId === 2 || dataValue.crmTypeId === 3) allEvent.user.batch1();

      },

      user : {

        batch1 : () => {

          let formData = new FormData();
          let file = dataValue.fileList[0];
          formData.append('stockFile', file)

          proxy.postFile('/admin/product/batch.1', formData)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                  dataValue.uploading = false;
                } else {
                  allEvent.user.batch2(res.data['fileIndex'])
                }
              })
        },

        batch2 : (fileIndex) => {

          let thisInput = {
            fileIndex : fileIndex,
            showAssessPrice : 1,
            crmTypeId : dataValue.crmTypeId,
            crmTypeId2 : 1
          }

          proxy.post('/admin/product/batch.2', thisInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                  dataValue.uploading = false;
                } else {
                  res.data['list'].forEach((v) => {
                    dataValue.userInput.list.push(v);
                  });

                  allEvent.user.batchUp()
                }
              })

        },

        batchUp : () => {

          // 添加调查表
          dataValue.dcb.forEach((v) => {
            dataValue.userInput.dcb.push({
              "index" : parseInt(v['idName']),
              "question" : v['title'],
              "checkValue" : v['checkValue'],
              "answer" : v['value']
            });
          });

          // console.log( dataValue.userInput)

          proxy.post('/admin/product/batch.up', dataValue.userInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                  dataValue.uploading = false;
                } else {
                  message.info("保存成功！");
                  dataValue.uploading = false;

                  emit('goClose');
                }
              })

        }

      },

      factory : {

        batchUp1 : () => {

          let formData = new FormData();
          let file = dataValue.fileList[0];
          formData.append('stockFile', file)

          proxy.postFile('/admin/factory/batch.up.1', formData)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                  dataValue.uploading = false;
                } else {
                  allEvent.factory.batchUp1Save(res.data['fileIndex'])
                }
              })
        },

        batchUp1Save : (fileIndex) => {

          dataValue.factoryInput.fileIndex = fileIndex;
          dataValue.factoryInput.crmCompanyId = parseInt(dataValue.company.value);


          let geDCBValue = (idName, valueName) => {
            for (let i = 0; i < dataValue.dcb.length; i++) {
              if (dataValue.dcb[i]['idName'] === idName) {
                return dataValue.dcb[i][valueName];
              }
            }
            return {}
          }


          dataValue.factoryInput.dcb = {
            "fileIndex" : "-1",
            "dcb1" : geDCBValue('dcb1', 'checkValues'),
            "dcb2" : geDCBValue('dcb2', 'checkValue'),
            "dcb3" : geDCBValue('dcb3', 'checkValue'),
            "dcb4" : geDCBValue('dcb4', 'checkValues'),
            "dcb5" : geDCBValue('dcb5', 'checkValue'),
            "dcb6" : dataValue.factoryInput.moneyTypeName,
            "dcb7" : geDCBValue('dcb7', 'checkValues'),
            "dcb8" : geDCBValue('dcb8', 'checkValues'),
            "dcb9" : geDCBValue('dcb9', 'checkValue'),
            "dcb10" : geDCBValue('dcb10', 'checkValue'),
            "dcb11" : geDCBValue('dcb11', 'checkValue'),
          };

          // console.log( dataValue.factoryInput );

          proxy.post('/admin/factory/batch.up.1.save', dataValue.factoryInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                  dataValue.uploading = false;
                } else {
                  message.info("保存成功！");
                  dataValue.uploading = false;

                  emit('goClose');
                }
              })
        }
      }
    }

    allEvent.init();

    return {
      dataValue,
      handleRemove,
      beforeUpload,
      allEvent
    }
  }
}
</script>

<style scoped>
.searchCompany { width: 100%; }
.aSelect { clear: both; float: left; width: 100%; }
.titleNode { margin: 10px 0 10px 0; color: #225DDC; }
.aSelect .select { width: 114px; float: left; }
.buttons { margin-top: 10px; clear: both; float: left; }
.tLine { margin-bottom: 1px; border-bottom: 0.01em solid #cccccc; padding-bottom: 15px; }
.lInfo { clear: both; float: left; margin: 50px 0 0 0; color: slateblue; font-size: 12px; }

.dcbDiv { width: 55%;float: left;padding-top: 10px; }
.aDCB { margin-bottom: 18px; }
.aDCB .title { font-weight: bold;color: #333333; }
.aDCB .xx { margin: 5px 0 0 15px; }
.aDCB .xx label { margin: 5px 5px 0; }
</style>