<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在查询数据 ..."></a-spin>
  <div v-if="dataValue.loading === false">
    <div class="aNode">
      <div class="company">{{ dataValue.data.companyName }}</div>
    </div>
    <div class="aNode">
      <div>英文名:</div>
      <div>{{ dataValue.data.englishName }}</div>
    </div>
    <div class="aNode">
      <div>类型:</div>
      <div>{{ dataValue.data.crmTypeName }}</div>
    </div>
    <div class="aNode">
      <div>电话:</div>
      <div>{{ dataValue.data.tel }}</div>
    </div>
    <div class="aNode">
      <div>地址:</div>
      <div>{{ dataValue.data.address }}</div>
    </div>
    <div class="aNode">
      <div>状态:</div>
      <div>{{ dataValue.data.runStatusName }}</div>
    </div>
    <div class="aNode">
      <div>注册时间:</div>
      <div>{{ dataValue.data.createTime }}</div>
    </div>
    <p style="font-weight: bold;float: left; clear: both; margin-top: 30px;">联系人</p>
    <div style="padding-left: 20px;">
      <template v-for="user in dataValue.data.users">
        <div class="aNode">
          <div>名字：</div>
          <div>{{ user.userName }}</div>
        </div>
        <div class="aNode">
          <div>手机号：</div>
          <div>{{ user.mobile }}</div>
        </div>
        <div class="aNode">
          <div>邮件：</div>
          <div>{{ user.email }}</div>
        </div>
        <br/>
      </template>
    </div>

  </div>
</template>

<script>
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import {reactive} from "vue";

export default {
  name : "user_info",
  props : [ 'company_id' ],

  setup(props) {

    let dataValue = reactive({
      loading : true,
      data : {}
    })

    let dataInput = {
      "crmCompanyId" : props.company_id
    }
    httpRequest.post('/admin/crm/get', dataInput)
        .then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.data = res.data;
            dataValue.loading = false;
          }
        })

    return {
      dataValue
    }

  }
}
</script>

<style scoped>
.company { font-size: 14px; font-weight: bold; }
.aNode { line-height: 35px; width: 90%; border-bottom: 1px solid #eeeeee; float: left; clear: both; }
.aNode > div:nth-child(1) { float: left; }
.aNode > div:nth-child(2) { float: right; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>