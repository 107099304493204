<script>
import store from "@/store";

export default {

  // 判断权限( 如果是多个权限，有一个满足为真 )
  check( ...functionIds ) {

    let allFun = store.state.functionIds;

    for( let i=0; i<allFun.length; i++ ){

      for( let j=0; j<functionIds.length; j++ ){

        // console.log(allFun[i] +'-'+ functionIds[j] +'-'+ ( allFun[i] === functionIds[j] ) )
        if( allFun[i] === functionIds[j] ) return true;

      }

    }

    return false;
  }

};
</script>
