<template>
  <table class="ahuiTable">
    <tr>
      <th>&nbsp;</th>
      <th>序号</th>
      <th>公司名</th>
      <th>联系人</th>
      <th>邮件</th>
    </tr>
    <template v-for="row in dataValue.list['companyLinkList']">
      <tr>
        <td class="min" @click="allEvent.noSend(row)">
          <check-outlined v-if="!row['noSend']" style="color:red;cursor: pointer;"/>
          <border-outlined v-if="row['noSend']" style="color: #cccccc;cursor: pointer;"/>
        </td>
        <td class="min">{{ row['rowNumber'] }}</td>
        <td>{{ row['companyName'] }}</td>
        <td class="min">{{ row['linkMan'] }}</td>
        <td class="min">{{ row['mail'] }}</td>
      </tr>
    </template>
  </table>
</template>

<script>
import {reactive} from "vue";

export default {
  name : "factory_stock_mail_detail",
  props : [ 'row_obj' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      list : props.row_obj
    });

    let allEvent = {
      noSend : (row)=>{
        row['noSend'] = ( row['noSend'] === undefined || row['noSend'] === false ) ? true : false;
      }
    }


    return { dataValue, allEvent }

  }
}
</script>

<style scoped>

</style>