<template>
  <table class="ahuiTable">
    <tr>
      <th>&nbsp;</th>
      <th>邮件</th>
      <th v-if="dataValue.historyMail.inputData.isSend===1">发送时间</th>
      <th v-if="dataValue.historyMail.inputData.isSend!==1">计划时间</th>
      <th>提交人</th>
      <th v-if="dataValue.historyMail.inputData.isSend===1">&nbsp;</th>
    </tr>
    <tr v-for="aMail in dataValue.historyMail.list">
      <td class="min">{{aMail['indexId']}}</td>
      <td>{{aMail['mail']}}</td>
      <td class="min">{{aMail['planTime']}}</td>
      <td class="min">{{aMail['opEmployeeName']}}</td>
      <td class="min" v-if="dataValue.historyMail.inputData.isSend===1">{{aMail['haveUnsubscribe']}}</td>
    </tr>
  </table>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "mail_history",
  props : [ 'factory_stockId', 'stock_batch_id', 'user_batch_id', 'is_send' ],
  emits : [ 'goMailClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      historyMail : {
        inputData : {
          factoryStockId : 0,
          stockBatchId : 0,
          userBatchId : 0,
          isSend : -1
        },
        list : []
      }
    });

    let allHttp = {

      historyMail : () => {

        dataValue.historyMail.inputData.factoryStockId = props.factory_stockId;
        dataValue.historyMail.inputData.stockBatchId = props.stock_batch_id;
        dataValue.historyMail.inputData.userBatchId = props.user_batch_id;
        dataValue.historyMail.inputData.isSend = props.is_send;

        httpRequest.post('/admin/ic7.mail/send.history', dataValue.historyMail.inputData)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.historyMail.list = res.data;
              }
            })
      },

      init : () => {
        allHttp.historyMail();
      }
    }
    allHttp.init();


    return {
      dataValue
    }
  }
}
</script>

<style scoped>

</style>