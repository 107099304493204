<template>

  <div class="pages" style="float: left; background-color: #ffffff;">
    <div class="toRight">
      <div class="info">每页{{ dataValue.numberOfPage }}条，共{{
          dataValue.thisPage
        }}/{{ dataValue.allPage }}页，{{ dataValue.allCount }}条
      </div>
      <div @click="toPage(1)" class="page default" v-if="dataValue.thisPage > 5">1</div>
      <div class="page ddd" v-if="dataValue.thisPage > 6">...</div>

      <template v-for="i in dataValue.allPage">
        <div v-if="i > dataValue.thisPage -5 && i < dataValue.thisPage + 5 && i !== dataValue.thisPage"
             class="page default" @click="toPage(i)">{{ i }} <!--一般页-->
        </div>
        <div v-if="i === dataValue.thisPage" class="page click">{{ i }}</div> <!--选中-->
      </template>

      <div class="page ddd" v-if="dataValue.thisPage < dataValue.allPage - 5">...</div>
      <div class="page default" @click="toPage(dataValue.allPage)"
           v-if="dataValue.thisPage < dataValue.allPage - 4">{{ dataValue.allPage }}
      </div>
    </div>
  </div>

</template>

<script>
import {reactive, watch} from "vue";

export default {
  name : "pages.vue",
  props : [ 'all_count', 'all_page', 'number_of_page', 'this_page' ],
  emits : [ 'toPage' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      allCount : 9000,
      allPage : 100,
      numberOfPage : 20,
      thisPage : 2
    });

    watch(() => props, (newProps) => {
      dataValue.allCount = newProps.all_count;
      dataValue.allPage = newProps.all_page;
      dataValue.numberOfPage = newProps.number_of_page;
      dataValue.thisPage = newProps.this_page;

    }, { deep : true, immediate : true });

    const toPage = (pageNumber) => {
      emit('toPage', pageNumber);
    }

    return {
      dataValue,
      toPage
    }
  }
}
</script>

<style scoped>
.pages {
  width: 100%;
  text-align: right;
  background-color: #ffffff;
}
.pages .toRight {
  float: right;
}
.pages .toRight .page {
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  padding: 0 5px 0 5px;
  text-align: center;
  float: left;
  margin: 3px;
  font-size: 12px;
  border-radius: 3px;
}

.pages .toRight .default {
  border: 1px solid #cccccc;
  cursor: pointer;
}
.pages .toRight .default:hover {
  border: 1px solid dodgerblue;
  color: dodgerblue;
}

.pages .toRight .click {
  border: 1px solid #ffffff;
  color: red;
}

.pages .toRight .ddd {
  border: 1px solid #ffffff;
  color: #cccccc;
}
.pages .toRight .info {
  float: left;
  padding: 0;
  margin: 0 15px 0 0;
  height: 20px;
  line-height: 30px;
  color: #999999;
}
</style>