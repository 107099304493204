<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" :tip="dataValue.loadingText"></a-spin>

  <div v-if="dataValue.loading === false" style="padding:5px 15px 5px 5px;">
    <div class="buttons">
      <div style="float: left; margin: 5px 15px 0 0;cursor: pointer;">
        <div v-if="!dataValue.onlyShowMail" @click="allEvent.showOnlyRow(true)">
          <border-outlined style="color: #cccccc;"/>
          只显示已匹配行
        </div>
        <div v-if="dataValue.onlyShowMail" @click="allEvent.showOnlyRow(false)">
          <check-outlined style="color: #cccccc;"/>
          显示全部的记录
        </div>
      </div>
      <text style="margin-left: 20px;">邮件模板：</text>
      <a-select style="width: 250px;margin-right: 10px;" v-model:value="dataValue.templateList.selectTempId">
        <template v-for="tem in dataValue.templateList.output.list">
          <a-select-option :value="tem['tempId']">{{ tem['tempLabel'] }}</a-select-option>
        </template>
      </a-select>
      <a-popconfirm :title="'确认要发送匹配的'+ dataValue.mailAllCount +'封邮件？'" ok-text="是" cancel-text="否"
                    @confirm="allHttp.ic7Mail.send">
        <a-button v-if="dataValue.mailAllCount > 0 && dataValue.templateList.output.list.length > 0" type="primary">
          邮件群发
        </a-button>
      </a-popconfirm>
    </div>
    <table class="ahuiTable">
      <tr>
        <th class="min">&nbsp;</th>
        <th class="min">序号</th>
        <th>型号</th>
        <th>品牌</th>
        <th>批号</th>
        <th class="min">数量</th>
        <th class="min">匹配邮件数</th>
      </tr>
      <template v-for="aRow in dataValue.detail.output.list">
        <tr v-if="dataValue.onlyShowMail === false || ( aRow != null && aRow['companyLinkAllCount'] != null && aRow['companyLinkAllCount'] > 0 )">
          <td class="min" @click="allEvent.select.check(aRow);">
            <check-outlined v-if="aRow.checked && aRow['companyLinkAllCount'] > 0" style="color:red;"/>
            <border-outlined v-if="!aRow.checked && aRow['companyLinkAllCount'] > 0" style="color: #cccccc;"/>
          </td>
          <td class="min" v-if="dataValue.crmTypeId === 1">{{ aRow['indexNumber'] }}</td>
          <td class="min" v-if="dataValue.crmTypeId !== 1">{{ aRow['rowNumber'] }}</td>
          <td>{{ aRow['modelName'] }}</td>
          <td>{{ aRow['manufacturerName'] }}</td>
          <td>{{ aRow['batchName'] }}</td>
          <td class="min number">{{ aRow['number'] }}</td>
          <td class="min number" :style="tools.priceColor2(aRow['companyLinkAllCount'])">
            <template v-if=" parseInt( aRow['companyLinkAllCount']) <= 0">{{ aRow['companyLinkAllCount'] }}</template>
            <a v-if=" parseInt( aRow['companyLinkAllCount']) > 0"
               @click="allEvent.mailCompanyDetail.open(aRow)">{{ aRow['companyLinkAllCount'] }}</a>
          </td>

        </tr>
      </template>
    </table>
  </div>


  <a-drawer
      title="查看已匹配的邮件列表"
      width=700
      :visible="dataValue.mailCompanyDetail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mailCompanyDetail.close"
  >
    <mailDetail :row_obj="dataValue.mailCompanyDetail.row_obj"/>
  </a-drawer>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import tools from "@/tools/small_methods"
import mailDetail from "@/view/stock/factory_stock_mail_detail"

export default {
  name : "mail_batch",
  props : [ "row_obj", "crm_type_id" ],
  emits : [ "goClose" ],
  components : { mailDetail },
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : false,
      loadingText : "正在加载数据 ...",
      rowObj : props.row_obj,
      crmTypeId : props.crm_type_id,
      mailAllCount : 0,

      onlyShowMail : false,

      mailCompanyDetail : {
        visible : false,
        row_obj : null,
      },

      // 模板列表
      templateList : {

        selectTempId : 0,

        input : {
          typeId : 2
        },
        output : {
          rowCount : 0,
          list : []
        }
      },

      detail : {

        input_1 : { // 工厂库存
          stockBatchId : 0,
          showPrice : 0,
          isDownload : 0
        },
        input_23 : { // 工厂销售和贸易商
          crmTypeId : 0,
          page : 1,
          numberOfPage : 9999999,
          companyName : "",
          userBatchValue : "",
          userBatchId : 0,
          userNodeId : 0,
          manufacturerName : "",
          modelName : "",
          isDownload : 0
        },
        output : {
          selectRow : 0,
          allCount : 0,
          list : []
        }

      }

    });




    let allEvent = {

      mailCompanyDetail : {

        open : (row) => {
          dataValue.mailCompanyDetail.visible = true;
          dataValue.mailCompanyDetail.row_obj = row;

          // console.log(row);
        },

        close : () => {
          dataValue.mailCompanyDetail.visible = false;

          // 重新计算下数量
          dataValue.mailCompanyDetail.row_obj['companyLinkAllCount'] = 0;
          dataValue.mailCompanyDetail.row_obj['companyLinkList'].forEach((v)=>{
            if( v['noSend'] !== true ) dataValue.mailCompanyDetail.row_obj['companyLinkAllCount']++;
          })
          allEvent.getAllCount();
        }
      },

      showOnlyRow : (type) => {
        dataValue.onlyShowMail = type;
      },

      // 判断有多少选中的邮件
      getAllCount : () => {

        dataValue.mailAllCount = 0;

        // console.log(dataValue.detail.output.list)

        dataValue.detail.output.list.forEach((row) => {
          if (row['checked'] === true && row['companyLinkAllCount'] > 0) {
            dataValue.mailAllCount += row['companyLinkAllCount'];
          }
        });
      },

      select : {

        check : (row) => {
          row['checked'] = !row['checked'];
          allEvent.getAllCount();
        }
      }

    }


    let allHttp = {

      templateList : () => {
        httpRequest.post('/admin/set/ic7.template/list', dataValue.templateList.input).then((res) => {
          // console.log(res.message);
          if (res.code === 200) {
            dataValue.templateList.output.rowCount = res['data']['rowCount'];
            dataValue.templateList.output.list = res['data']['list'];

            if (dataValue.templateList.output.list.length > 0) {
              dataValue.templateList.selectTempId = dataValue.templateList.output.list[0]['tempId'];
            }
          }
        })
      },

      detailList : () => {

        dataValue.loading = true;
        dataValue.loadingText = "正在加载数据 ......";


        if( dataValue.crmTypeId === 1 ){

          dataValue.detail.input_1.stockBatchId = dataValue.rowObj['stockBatchId'];
          httpRequest.post('/admin/stock/list.batch.detail', dataValue.detail.input_1).then((res) => {

            dataValue.loading = false;

            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.detail.output.allCount = res.data.list.length;
              dataValue.detail.output.list = res.data.list;

              for (let i = 0; i < dataValue.detail.output.list.length; i++) {
                dataValue.detail.output.list[i]['companyLinkAllCount'] = 0;
                dataValue.detail.output.list[i]['companyLinkList'] = [];
                dataValue.detail.output.list[i]['companyLinkAllCount'] = '请求中 ...'
                allHttp.companyLink(dataValue.detail.output.list[i], i);
              }
            }
          });
        }

        if( dataValue.crmTypeId === 2 || dataValue.crmTypeId === 3 ){

          dataValue.detail.input_23.crmTypeId = dataValue.crmTypeId;
          dataValue.detail.input_23.userBatchId = dataValue.rowObj['userBatchId'];

          httpRequest.post('/admin/user.need/inquiry.list', dataValue.detail.input_23).then((res) => {

            dataValue.loading = false;

            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.detail.output.allCount = res.data.list.length;
              dataValue.detail.output.list = res.data.list;

              for (let i = 0; i < dataValue.detail.output.list.length; i++) {
                dataValue.detail.output.list[i]['companyLinkAllCount'] = 0;
                dataValue.detail.output.list[i]['companyLinkList'] = [];
                dataValue.detail.output.list[i]['companyLinkAllCount'] = '请求中 ...'
                allHttp.companyLink(dataValue.detail.output.list[i], i);
              }
            }
          });
        }
      },

      // 获得型号匹配的邮件数
      companyLink : (row, indexTime) => {

        window.setTimeout(() => {

          let url = "";
          if( dataValue.crmTypeId === 1 ) url = "/order/get.order.company.link.kc"
          if( dataValue.crmTypeId === 2 || dataValue.crmTypeId === 3 ) url = "/order/get.order.company.link.xh"


          let input = {
            "modelName" : row['modelName']
          }

          httpRequest.postEIC(url, input).then((res) => {
            if (res.code === 200) {

              if (row['companyLinkAllCount'] === '请求中 ...') row['companyLinkAllCount'] = 0;

              row['companyLinkAllCount'] = row['companyLinkAllCount'] + res.data['allCount'];
              Array.prototype.push.apply(row['companyLinkList'], res.data['list']);

              if (res.data['allCount'] > 0) row['checked'] = true;

              allEvent.getAllCount();
            }
          });

        }, indexTime * 15)

      },

      ic7Mail : {

        getGroupId : () => {

          return new Promise((resolve, reject) => {

            httpRequest.post('/admin/ic7.mail/mail.group', {}).then((res) => {
              if (res.code !== 200) {
                reject(res.message);
              } else {
                let groupId = res['data']['groupId'];
                resolve(groupId);
              }
            })

          })

        },

        send : () => {

          // 发送已经开始
          dataValue.loading = true;
          dataValue.loadingText = "正在提交" + dataValue.mailAllCount + "封邮件请求，请稍后 ......";

          // 获得GroupId
          allHttp.ic7Mail.getGroupId().then((groupId) => {

            let input = {
              "groupId" : groupId,
              "tempId" : dataValue.templateList.selectTempId,
              "planSendTime" : "",
              "factoryStockId" : 0,
              "stockBatchId" : ( dataValue.crmTypeId === 1 ? dataValue.rowObj['stockBatchId'] : 0 ),
              "userBatchId" : ( dataValue.crmTypeId === 2 || dataValue.crmTypeId === 3 ? dataValue.rowObj['userBatchId'] : 0 ),
              "links" : []
            }

            dataValue.detail.output.list.forEach((aRow) => {

              // 只显示有匹配的记录
              if (aRow['companyLinkAllCount'] > 0 && aRow['checked'] === true) {

                // 循环所有的联系人
                aRow['companyLinkList'].forEach((linkMan) => {

                  if( linkMan['noSend'] !== true ){ // 去除不发送的单个邮件

                    input.links.push({
                      "company" : linkMan['companyName'],
                      "user" : linkMan['linkMan'],
                      "model" : aRow['modelName'],
                      "mail" : linkMan['mail']
                    });

                  }

                });
              }
            });

            // 发送信息
            httpRequest.post('/admin/ic7.mail/mail.plan', input).then((res) => {
              if (res.code !== 200) {

                message.error(res.message);

              } else {

                dataValue.loading = false;
                message.info("邮件已提交，审批后将发送。");
                emit('goClose', dataValue.rowObj);

              }
            })

          });

        }


      },


      init : () => {
        allHttp.detailList();
        allHttp.templateList();
      }

    }

    allHttp.init();


    return {
      dataValue,
      allHttp,
      allEvent,
      tools
    }
  }
}
</script>

<style scoped>
.buttons { position: fixed; right: 38px; top: 12px; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>