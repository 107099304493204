<template>


  <a-spin class="loadingClass" v-if="dataValue.saveing" :tip="dataValue.saveingText"></a-spin>
  <div v-if="!dataValue.saveing">
    <div style="padding-right: 20px;">
      <div class="aNode">
        <span>库存来自公司：</span>
        <span>{{ dataValue.props.companyName }}</span>
      </div>
      <div class="aNode">
        <span>待群发型号数量：</span>
        <span>{{ dataValue.upSize }}条型号记录</span>
      </div>

      <div class="aNode">
        <span>总发送量：</span>
        <span>
        <label style="color: #cccccc;">(自动屏蔽7日已发记录)&nbsp;</label>
        <label style="color: red;">{{ dataValue.upSize * dataValue.eachOfNumber }}</label>
      </span>
      </div>
      <div class="aNode">
        <span>每个型号推广条数：</span>
        <span>
          <a-input v-model:value="dataValue.eachOfNumber" style="width:80px;text-align:right;padding-right: 2px;"
                   type="number"/>
      </span>
      </div>

      <div class="aNode">
        <span>过期时间(单位小时)：</span>
        <span>
          <a-input v-model:value="dataValue.endTimeSize" style="width:80px;text-align:right;padding-right: 2px;"
                   type="number"/>
        </span>
      </div>
      <div class="aNode">
        <span>我的手机号：</span>
        <span>
          <a-tooltip>
            <template #title>此号码会传递给用户。</template>
            <a-input style="width:130px;text-align: right;" v-model:value="dataValue.userInfo['mobile']"/>
          </a-tooltip>
        </span>
      </div>
      <div class="aNode">
        <span>预计发送时间：</span>
        <span>
          <a-date-picker show-time placeholder="默认为立即执行" @change="allEvent.setTimeChange" />
        </span>
      </div>

    </div>
    <div class="buttons">
      <a-button type="primary" @click="BatchSend">群发推广-C</a-button>
    </div>
  </div>
</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";

export default {
  name : "ic7_batch",
  props : [ 'row_objs', 'index_id_name', 'crm_type_id', 'company_name' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      props : {
        rowObjs : [],
        indexIdName : props.index_id_name,
        crmTypeId : props.crm_type_id,
        companyName : props.company_name
      },

      saveing : false,
      saveingText : "正在加载 ...",
      eachOfNumber : "0",
      endTimeSize : "12",
      planSendTime : "",
      userInfo : {},

      upSize : 0,
      modelDetail : []
    });

    dataValue.props.rowObjs = props.row_objs;

    // console.log(dataValue.props)


    let getHttp = {

      userInfo : {

        get : () => {

          proxy.postEIC('/human/employee/get.info', {})
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  dataValue.userInfo = res.data;
                }
              })
        },

        setMobile : () => {
          proxy.postEIC('/human/employee/set.mobile', {
            "newMobile" : dataValue.userInfo.mobile
          }).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            }
          })
        }

      },

      modelDetail : {

        crmTypeId1 : (rowObjs) => { // 工厂库存

          let allCompanySize = rowObjs.length;

          let closeLoading = () => { // 判断是否已经全部加载完，才关闭进度条
            allCompanySize--;
            if (allCompanySize <= 0) dataValue.saveing = false;
          }


          rowObjs.forEach((aCompany) => {

            let dataInput = {
              "stockBatchId": aCompany['stockBatchId'],
              "showPrice": 1,
              "isDownload": 0
            }
            httpRequest.post('/admin/stock/list.batch.detail', dataInput).then((res) => {

              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.upSize += res.data.allCount;

                res.data.list.forEach((row)=>{
                  dataValue.modelDetail.push(row);
                });

              }

              closeLoading();
            })


          });
        },

        crmTypeId23 : (rowObjs, indexIdName) => { // 工厂销售、贸易商


          let allCompanySize = rowObjs.length;

          let closeLoading = () => { // 判断是否已经全部加载完，才关闭进度条
            allCompanySize--;
            if (allCompanySize <= 0) dataValue.saveing = false;
          }


          rowObjs.forEach((aCompany) => {

            let dataInput = {
              "crmTypeId": dataValue.props.crmTypeId,
              "page" : 1,
              "numberOfPage" : 9999999,
              "companyName" : "",
              "userBatchId" : parseInt(aCompany[indexIdName]),
              "userNodeId" : 0,
              "manufacturerName" : "",
              "modelName" : ""
            }
            httpRequest.post('/admin/user.need/inquiry.list', dataInput).then((res) => {

              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.upSize += res.data.allCount;

                res.data.list.forEach((row)=>{
                  dataValue.modelDetail.push(row);
                });

              }

              closeLoading();
            })


          });

        }

      },


      init : () => {

        dataValue.saveing = true;
        dataValue.saveingText = "正在加载订单的详细信息...";

        getHttp.userInfo.get();

        dataValue.upSize = 0;
        dataValue.modelDetail = [];

        if( dataValue.props.crmTypeId === 1 ) getHttp.modelDetail.crmTypeId1( dataValue.props.rowObjs );
        if (dataValue.props.crmTypeId === 2) getHttp.modelDetail.crmTypeId23(dataValue.props.rowObjs, dataValue.props.indexIdName);
        if (dataValue.props.crmTypeId === 3) getHttp.modelDetail.crmTypeId23(dataValue.props.rowObjs, dataValue.props.indexIdName);
      }

    }

    getHttp.init();


    let allEvent = {

      setTimeChange : (value, dateString) => {
        dataValue.planSendTime = dateString;
      }

    }


    let BatchSend = () => {


      if (dataValue.endTimeSize <= 0) {
        message.error("过期时间必须大于0")
        return
      }

      if (dataValue.eachOfNumber <= 0) {
        message.error("每个型号的发送数量必须大于0")
        return
      }

      dataValue.saveing = true;
      dataValue.saveingText = "正在保存数据，请勿关闭窗口 ...";

      let inputData = {

        "askUserId" : dataValue.userInfo['employeeId'],
        "askUserName" : dataValue.userInfo['employeeName'],
        "askUserMobile" : dataValue.userInfo['mobile'],
        "askCompany" : "芯链芯",
        "sendType" : dataValue.props.crmTypeId,
        "eachOfNumber" : parseInt( dataValue.eachOfNumber),
        "endTimeSize" : parseInt( dataValue.endTimeSize ),
        "planSendTime": dataValue.planSendTime,
        "modelList" : []
      }
      dataValue.modelDetail.forEach((row) => {

        if (parseInt(row['number']) === 0) {
          row['number'] = "999" // 没有数量写成默认的999个
        }

        // console.log(row)
        // console.log(dataValue.props.indexIdName)

        inputData.modelList.push({
          "modelName" : row['modelName'],
          "manufacturerName" : row['manufacturerName'],
          "batchName" : row['batchName'],
          "number" : parseInt(row['number']),
          "factoryStockId": 0,
          "stockBatchId": dataValue.props.crmTypeId === 1 ? parseInt(row[dataValue.props.indexIdName]) : 0,
          "userBatchId": dataValue.props.crmTypeId === 2 || dataValue.props.crmTypeId === 3 ? parseInt(row[dataValue.props.indexIdName]) : 0
        });
      });


      // console.log(inputData)


      proxy.post('/admin/ic7/by.plan', inputData).then((res) => {
        if (res.code !== 200) {
          message.error(res.message);
        } else {
          getHttp.userInfo.setMobile();
          message.info("将在10分钟后发送"+ ( dataValue.upSize * dataValue.eachOfNumber ) +"条短信，请勿重复提交！", 3);
          emit('goClose');
        }

        dataValue.saveing = false;
      })
    }


    return {
      dataValue,
      BatchSend,
      allEvent
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.aNode { border-bottom: 1px solid #eeeeee; line-height: 50px; }
.aNode > span:nth-child(2) { float: right; }
.aNode .spanObj { float: right; }
.buttons { text-align: center;margin-top: 40px; height: 50px }
</style>